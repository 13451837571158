<template lang="pug">
.animated.fadeIn
  b-tabs.w-100(pills lazy)
    b-tab(:title = '$t("edit")')
      classifier-editable-values-list(
        v-for           = '(values, type) in editableClassifiers'
        :key            = 'type'
        :classifiers     = 'values'
        :classifier-type = 'type'
        @update         = 'updateEditableClassifier'
        @remove         = 'removeEditableClassifier'
      )
      component(
        v-for           = '(values, type) in classifierEditableList'
        :key            = 'type'
        :is             = 'classifierComponent(type)'
        :classifiers     = 'values'
        :classifier-type = 'type'
        editable
        @add            = 'createClassifier'
        @update         = 'updateClassifier'
      )
    b-tab(:title = '$t("view")')
      ClassifierValuesList(
        v-for           = '(values, type) in sortedClassifiers'
        :key            = 'type'
        :classifiers     = 'values'
        :classifier-type = 'type'
      )
    template(#tabs-end)
      button.ml-auto.btn-link.btn(@click = 'showNewModal = true') {{ $t('addNew') }}

  classifier-new-modal(
    v-model         = 'showNewModal'
    :classifier-keys = 'editableClassifiersOptions'
    @save           = 'saveEditableClassifier'
  )

</template>


<script>
import ClassifierValuesList from '@/views/admin/classifiers/ClassifierValuesList'
import FeeTypeValuesList from '@/views/admin/classifiers/FeeTypeValuesList'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'
import { mapActions, mapGetters, mapState } from 'vuex'
import ClassifierNewModal from '@/views/admin/classifiers/ClassifierNewModal'
import ClassifierEditableValuesList from '@/views/admin/classifiers/ClassifierEditableValuesList'

export default {
  name: 'classifier-list',

  components: { ClassifierEditableValuesList, ClassifierNewModal, ClassifierValuesList },

  i18nOptions: {},

  data: () => ({
    showNewModal: false
  }),

  computed: {
    ...mapState('classifiers', ['classifiers', 'editableClassifiers']),
    ...mapGetters('classifiers', ['classifierList']),
    sortedClassifiers () {
      return sortBy(Object.entries(this.classifiers), ([key]) => key).reduce((res, [key, value]) => ({ ...res, [key]: value }), {})
    },
    classifierEditableList () {
      const editableCodes = Object.values(this.classifierList('editableClassifiers'))
        .map(({ name }) => name)
        .sort()
      return pick(this.classifiers, editableCodes)
    },
    editableClassifiersOptions () {
      return Object.keys(this.editableClassifiers)
    }
  },

  created () {
    this.loadFactoringClassifiers()
    this.loadEditableClassifiers()
  },

  methods: {
    ...mapActions('classifiers', [
      'createClassifier',
      'updateClassifier',
      'loadFactoringClassifiers',
      'loadEditableClassifiers',
      'createEditableClassifier',
      'updateEditableClassifier',
      'removeEditableClassifier'
    ]),
    classifierComponent (classifierType) {
      return classifierType === 'FEE_TYPE_ENTITY' ? FeeTypeValuesList : ClassifierValuesList
    },
    async saveEditableClassifier ({ payload, done }) {
      await this.createEditableClassifier({ classifierData: payload })
      done()
    }
  }
}
</script>


<i18n>
en:
  edit:   "Edit"
  view:   "View"
  addNew: "+ Add new classifier"
et:
  edit:   "Edit"
  view:   "View"
  addNew: "+ Add new classifier"
ru:
  edit:   "Edit"
  view:   "View"
  addNew: "+ Add new classifier"
</i18n>
