<template lang="pug">
.card.border-bottom-0.classifier-editable-values-list
  .card-header(v-b-toggle = 'classifierType') {{ classifierType | sentenceCase | ucFirst }}
  b-collapse(
    :id = 'classifierType'
    visible
  )
    .card-body.border-bottom
      .table-responsive
        table.table.table-hover.mb-0
          thead: tr
            th.w-25 ID
            th.w-25 {{ $t('code') }}
            th.w-25 {{ $t('value') }}
            th.w-25 {{ $t('common:enabled') }}
            th
          tbody
            classifier-editable-values-list-item(
              v-for      = 'classifier in sortClassifiers'
              :key       = 'classifier.id'
              :classifier = 'classifier'
              :type      = 'classifierType'
              @input     = 'updateClassifier'
              @remove    = 'removeClassifier'
            )
</template>


<script>
import ClassifierValueEdit from '@/views/admin/classifiers/ClassifierValueEdit'
import FeeTypeValuesListItem from '@/views/admin/classifiers/FeeTypeValuesListItem'
import ClassifierEditableValuesListItem from '@/views/admin/classifiers/ClassifierEditableValuesListItem'

export default {
  name: 'classifier-editable-values-list',
  components: { ClassifierEditableValuesListItem, FeeTypeValuesListItem, ClassifierValueEdit },
  props: {
    classifiers: {
      type: Array,
      required: true
    },
    classifierType: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.classifiers))
      const sortList = list.sort(function (a, b) {
        const nameA = a.classifierValue
        const nameB = b.classifierValue
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  methods: {
    updateClassifier (e) {
      this.$emit('update', { ...e, classifierKey: this.classifierType })
    },
    removeClassifier (e) {
      this.$emit('remove', { ...e, classifierKey: this.classifierType })
    }
  }
}
</script>


<i18n>
en:
  code:     "Code"
  value:    "Value"
et:
  code:     "Code"
  value:    "Value"
ru:
  code:     "Code"
  value:    "Value"
</i18n>


<style scoped lang="scss">
  .card-header {
    cursor: pointer;
  }
</style>
