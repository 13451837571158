<template lang="pug">
  form.form-inline.animated.fadeIn(@submit.prevent = 'onSubmit')
    fi-form-field.mn-lg-0
      input.form-control-sm.form-control.mr-sm-2(v-model = 'classifierName')
    button.btn.btn-primary.mr-sm-2(:disabled = 'saving || !changed'
                                   type      = 'submit')
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(v-if = 'cancelable'
                             :disabled      = 'saving'
                             @click.prevent = '$emit("cancel")') {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'

export default {
  name: 'classifier-value-edit',

  components: { FiFormField },

  props: {
    value: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      default: false
    },
    cancelable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      classifierName: this.value
    }
  },

  computed: {
    changed () {
      return this.classifierName !== this.value && this.classifierName
    }
  },

  methods: {
    onSubmit () {
      this.$emit('input', this.classifierName)
      this.classifierName = ''
    }
  }
}
</script>
