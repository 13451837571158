<template lang="pug">
tr.classifier-editable-values-list-item
  td {{ classifier.id }}
  td(@dblclick = 'setEdit()')
    input.animated.fadeIn.form-control-sm.form-control.mr-sm-2(
      v-if    = 'edit'
      v-model = 'classifierData.classifierCode'
    )
    .animated.fadeIn(v-else) {{ classifier.classifierCode }}
  td(@dblclick = 'setEdit()')
    input.animated.fadeIn.form-control-sm.form-control.mr-sm-2(
      v-if    = 'edit'
      v-model = 'classifierData.classifierValue'
    )
    .animated.fadeIn(v-else) {{ classifier.classifierValue }}
  td(@dblclick = 'setEdit()')
    fi-switch(
      v-if      = 'edit'
      v-model   = 'classifierData.isEnabled'
    )
    fi-switch(
      v-else
      :value = 'classifier.isEnabled'
      read-only
    )
  td.text-nowrap
    .btn-toolbar
      .btn-group.animated.fadeIn(v-if = 'edit')
        button.btn.btn-primary.mr-sm-2(
          :disabled      = 'editing'
          @click.prevent = 'submit'
        ) {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'editing')
        button.btn.btn-secondary(
          :disabled      = 'editing'
          @click.prevent = 'setEdit(false)'
        ) {{ $t('common:cancel') }}
      .btn-group.animated.fadeIn(v-else)
        button.btn.btn-link.text-danger(@click.prevent = '$emit("remove", { id: classifier.id})')
          i.far.fa-trash-alt.fa-lg
        button.py-0.btn.btn-link(@click.prevent = 'setEdit()')
          i.far.fa-edit.fa-lg
</template>


<script>
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import ClassifierValueEdit from '@/views/admin/classifiers/ClassifierValueEdit'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'

export default {
  name: 'classifier-editable-values-list-item',
  components: { FiSwitch, FiSelect, ClassifierValueEdit },
  props: {
    classifier: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },

  data: () => ({
    edit: false,
    classifierData: {
      classifierCode: '',
      classifierValue: '',
      isEnabled: false
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName']),
    editing () {
      return this.$vueLoading.isLoading(`classifiers:${this.type}:${this.classifier.id}:save`)
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data())
      this.classifierData = {
        ...this.classifierData,
        ...pick(this.classifier, Object.keys(this.classifierData))
      }
    },
    setEdit (value = !this.edit) {
      this.resetData()
      this.edit = value
    },
    submit () {
      this.$emit('input', { id: this.classifier.id, ...this.classifierData })
      this.resetData()
    }
  }
}
</script>
