<template lang="pug">
tr
  td {{ classifier.id }}
  td {{ classifier.name }}
  td(@dblclick = 'setEdit()')
    input.animated.fadeIn.form-control-sm.form-control.mr-sm-2(
      v-if    = 'edit'
      v-model = 'classifierData.human'
    )
    .animated.fadeIn(v-else) {{ classifier.human }}
  td(@dblclick = 'setEdit()')
    fi-select.animated.fadeIn(
      v-if     = 'edit && !classifier.systemFee'
      v-model  = 'classifierData.feeGroup'
      :options = 'optionsFromClassifier("feeGroup")'
      required
      sm
    )
    .animated.fadeIn(v-else) {{ classifierByName(classifier.feeGroup.classifier, classifier.feeGroup.code).human }}
  td
    fi-switch(
      v-if      = 'edit'
      v-model   = 'classifierData.affectsAprAndTotalCreditCost'
    )
    fi-switch(
      v-else
      :value = 'classifier.affectsAprAndTotalCreditCost'
      read-only
    )
  td.text-nowrap
    template(v-if = 'edit')
      button.btn.btn-primary.mr-sm-2(
        :disabled      = 'editing'
        @click.prevent = 'submit'
      ) {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'editing')
      button.btn.btn-secondary(
        :disabled      = 'editing'
        @click.prevent = 'setEdit(false)'
      ) {{ $t('common:cancel') }}
    button.fa-pull-right.py-0.btn.btn-link(
      v-else
      @click.prevent = 'setEdit()'
    )
      i.far.fa-edit.fa-lg
</template>


<script>
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import ClassifierValueEdit from '@/views/admin/classifiers/ClassifierValueEdit'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'

export default {
  name: 'fee-type-values-list-item',
  components: { FiSwitch, FiSelect, ClassifierValueEdit },
  props: {
    classifier: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },

  data: () => ({
    edit: false,
    classifierData: {
      human: '',
      feeGroup: '',
      affectsAprAndTotalCreditCost: false
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName']),
    editing () {
      return this.$vueLoading.isLoading(`classifiers:${this.type}:${this.classifier.id}:save`)
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data())
      this.classifierData = {
        ...this.classifierData,
        ...pick(this.classifier, Object.keys(this.classifierData))
      }
      this.classifierData.feeGroup = this.classifier.feeGroup.code
    },
    setEdit (value = !this.edit) {
      this.resetData()
      this.edit = value
    },
    submit () {
      this.$emit('input', { id: this.classifier.id, ...this.classifierData })
      this.resetData()
    }
  }
}
</script>
