<template lang="pug">
fi-modal.classifier-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("key")'
    )
      fi-select(
        v-model  = 'classifierData.classifierKey'
        :options = 'classifierKeys'
      )
      input.form-control.form-control-sm(v-model = 'classifierData.classifierKey')

    fi-form-field(
      :label = '$t("code")'
    )
      input.form-control.form-control-sm(v-model = 'classifierData.classifierCode')

    fi-form-field(
      :label = '$t("value")'
    )
      input.form-control.form-control-sm(v-model = 'classifierData.classifierValue')

  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>

<script>
import FiModal from '@/components/FiModal'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'

export default {
  name: 'classifier-edit-modal',

  components: { FiSelect, FiFormField, FiModal },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    loader: {
      type: String,
      default: ''
    },
    classifierKeys: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    classifierData: {
      classifierCode: '',
      classifierKey: '',
      classifierValue: '',
      isEnabled: true
    }
  }),

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this, this))
    },
    onSubmit () {
      if (this.classifierData.classifierKey) {
        this.$emit('save', {
          done: () => this.$refs?.modal?.hide(),
          payload: this.classifierData
        })
      }
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    }
  }
}
</script>


<i18n>
en:
  title: "Add new classifier"
  key:   "Key"
  code:  "Code"
  value: "Value"
et:
  title: "Add new classifier"
  key:   "Key"
  code:  "Code"
  value: "Value"
ru:
  title: "Add new classifier"
  key:   "Key"
  code:  "Code"
  value: "Value"
</i18n>
