<template lang="pug">
tr
  td {{ classifier.id }}
  td {{ classifier.name }}
  td(@dblclick = 'setEdit()')
    classifier-value-edit(v-if    = 'edit'
                          v-model = 'classifierValue'
                          :saving = 'editing'
                          cancelable
                          @cancel = 'setEdit()')
    .animated.fadeIn(v-else) {{ classifier.human }}
      button.fa-pull-right.py-0.btn.btn-link(v-if           = 'editable'
                                          @click.prevent = 'setEdit()')
        i.far.fa-edit.fa-lg
</template>


<script>
import ClassifierValueEdit from '@/views/admin/classifiers/ClassifierValueEdit'
export default {
  name: 'classifier-values-list-item',
  components: { ClassifierValueEdit },
  props: {
    classifier: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },

  data: () => ({
    edit: false
  }),

  computed: {
    classifierValue: {
      get () {
        return this.classifier.human
      },
      set (human) {
        this.$emit('input', { id: this.classifier.id, human })
        this.setEdit(false)
      }
    },
    editing () {
      return this.$vueLoading.isLoading(`classifiers:${this.type}:${this.classifier.id}:save`)
    }
  },

  methods: {
    setEdit (value = !this.edit) {
      this.edit = value
    }
  }
}
</script>
