<template lang="pug">
.card.border-bottom-0
  .card-header(v-b-toggle = 'classifierType') {{ classifierType | sentenceCase | ucFirst }}
  b-collapse(:id      = 'classifierType'
             :visible = 'editable')
    .card-body.border-bottom
      .table-responsive
        table.table.table-hover.mb-0
          thead: tr
            th.w-25 ID
            th.w-25 {{ $t('code') }}
            th.w-25 {{ $t('name') }}
          tbody
            ClassifierValuesListItem(v-for       = 'classifier in classifiers'
                                     :key        = 'classifier.id'
                                     :classifier = 'classifier'
                                     :type       = 'classifierType'
                                     :editable   = 'editable'
                                     @input      = 'updateClassifier')
          tbody(v-if = 'editable'): tr
            td
            td
            td
              ClassifierValueEdit(:saving = 'inserting'
                                  @input  = 'addClassifier')
</template>


<script>
import ClassifierValueEdit from '@/views/admin/classifiers/ClassifierValueEdit'
import ClassifierValuesListItem from '@/views/admin/classifiers/ClassifierValuesListItem'

export default {
  name: 'classifier-values-list',
  components: { ClassifierValuesListItem, ClassifierValueEdit },
  props: {
    classifiers: {
      type: Object,
      required: true
    },
    classifierType: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: () => ({
    addNew: false
  }),

  computed: {
    inserting () {
      return this.$vueLoading.isLoading(`classifiers:${this.classifierType}:save`)
    }
  },

  methods: {
    addClassifier (human) {
      this.$emit('add', { type: this.classifierType, human })
    },
    updateClassifier (e) {
      this.$emit('update', { ...e, type: this.classifierType })
    }
  }
}
</script>


<i18n>
en:
  code: "Code"
  name: "Name"
et:
  code: "Code"
  name: "Name"
ru:
  code: "Code"
  name: "Name"
</i18n>


<style scoped lang="scss">
  .card-header {
    cursor: pointer;
  }
</style>
